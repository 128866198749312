




















































































































































































































































































































































import { reactive, toRefs, SetupContext, ref, computed, watchEffect } from '@vue/composition-api';
import { ActionTypes } from '@/store/modules/user/actions';
import { ObjectId } from 'bson';
import { useDbGetters, useUserActions, useUserGetters } from '@/store';
import Loading from '@/components/Loading.vue';
import AOtpInput from '@/components/atoms/AOtpInput.vue';
import { onLogin } from '@/vue-apollo';
import ATeleInput from '@/components/atoms/ATeleInput.vue';
import axios from 'axios';

interface IUserForm {
  email: string;
  password: string;
  resetEmail: string;
  show1: boolean;
  dialog: boolean;
  error: string;
  forcedPassDialog: boolean;
}

interface IUi {
  msg: string;
  type: string;
  loading: boolean;
}
export default {
  components: {
    Loading,
    AOtpInput,
    ATeleInput
  },

  setup(props: any, context: SetupContext) {
    const { collection } = useDbGetters(['collection']);
    const isEmail = ref(false);
    const isOtpSent = ref(false);
    const isPhone = ref(true);
    const verifying = ref(false);
    const signingIn = ref(false);
    const otp = ref('');
    const token = ref('');
    const isEmailOtp = ref(false);
    const isChanging = ref(false);
    const otpMsg = ref('');
    const isPhoneValid = ref(false);
    const state: IUserForm = reactive({
      email: '',
      password: '',
      resetEmail: '',
      show1: false,
      dialog: false,
      forcedPassDialog: false,
      error: '',
      type: 'email_signin'
    });
    const phoneState = reactive({
      phone_number: '',
      formatted_number: '',
      type: 'phone_number_signin'
    });
    const ui: IUi = reactive({
      msg: '',
      type: 'success',
      loading: false
    });
    const userId = ref('');
    const userObjId = ref('');
    const verificationMsg = ref('');
    const {
      root: { $router, $route }
    } = context;

    const isSending = ref(false);

    if (context.root.$route.query.otpSent) {
      isOtpSent.value = context.root.$route?.query?.otpSent;
      phoneState.formatted_number = context.root.$route.query.phone;
    }

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted) {
        phoneState.formatted_number = `+${val.countryCallingCode}${val.formatted}`;
        isPhoneValid.value = val.valid;
      } else {
        phoneState.formatted_number = '';
      }
    }

    watchEffect(() => {
      if ($route.query && $route.query.message && $route.query.success) {
        verificationMsg.value = $route.query.message;
        isEmail.value = true;
        isPhone.value = false;
      }
      if ($route.query.pswChanged || $route.query.emailChanged) {
        isEmail.value = true;
        isPhone.value = false;
      }
    });

    const { loginUser } = useUserActions([ActionTypes.loginUser]);
    const { getObjectId } = useUserGetters(['getObjectId']);
    // const { sendResetPassword } = useAuthActions(['sendResetPassword']);

    const isValidOtp = computed(() => {
      if (otp.value.length === 6) {
        return true;
      }
      return false;
    });

    const sendResetPasswordEmail = async (): Promise<void> => {
      ui.loading = true;
      isSending.value = true;
      try {
        const data = { email: state.resetEmail.toLowerCase() };
        await axios.post(`${process.env.VUE_APP_AUTH0}/change-password`, data).then(() => {
          ui.type = 'success';
          ui.msg = 'Reset password email has been sent';
          isSending.value = false;
          state.dialog = false;
        });
      } catch (err) {
        ui.msg = 'Reset password email could not be sent';
        ui.type = 'error';
        isSending.value = false;
      }
      ui.loading = false;
    };

    const login = async (val): Promise<void> => {
      try {
        const user = await loginUser(val);
        try {
          await onLogin(token.value);
        } catch (err) {
          console.log(err);
        }
        if ($route.params.page) $router.push({ path: $route.params.page });
        else if ($route.query.redirect === 'guide') {
          const { collection } = useDbGetters(['collection']);
          const { getObjectId } = useUserGetters(['getObjectId']);
          await collection.value!('Program')
            .insertOne({
              organizers: [getObjectId.value],
              participants: [],
              dateCreated: new Date(),
              licensed: false
            })
            .then(result => {
              $router.push({
                name: 'guide',
                params: { programId: result.insertedId, page: '0' }
              });
            });
        } // $router.push({ path: $route.query.redirect });
        else $router.push({ name: 'setup' });
      } catch (err) {
        if (err.statusCode === 401)
          state.error = 'That email and password combination does not exist';
        else state.error = err;
      }
    };

    async function verifyToken(val) {
      token.value = val;
      try {
        const data = {
          token: val
        };

        const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/verify-jwt`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        collection.value!('User')
          .findOne({
            userId: resp.data.sub
          })
          .then(user => {
            login(user);
            verifying.value = false;
          });
      } catch (error) {
        console.log(error);
        otpMsg.value = 'Something went wrong';
        ui.type = 'error';
        signingIn.value = false;
        verifying.value = false;
      }
    }

    async function signIn() {
      signingIn.value = true;
      try {
        const API_ENDPOINT = process.env.VUE_APP_AUTH0;

        const stateEmail = {
          email: state.email,
          password: state.password,
          type: 'email_signin'
        };

        const stateEmailOtp = {
          email: state.email,
          type: 'email_otp_signin'
        };

        const stateSms = {
          phone_number: phoneState.formatted_number,
          type: 'phone_number_signin'
        };

        let data = {};

        if (isEmail.value) {
          data = stateEmail;
        } else if (isEmailOtp.value) {
          data = stateEmailOtp;
        } else {
          data = stateSms;
        }
        const resp = await axios.post(`${API_ENDPOINT}/sign-in`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (resp.status === 200 && isPhone.value) {
          ui.type = 'success';
          ui.msg = resp.data.message;
          isOtpSent.value = true;
          signingIn.value = false;
        } else if (resp.status === 200 && isEmailOtp.value) {
          ui.type = 'success';
          ui.msg = resp.data.message;
          isOtpSent.value = true;
          signingIn.value = false;
        } else {
          ui.type = 'success';
          ui.msg = resp.data.message;
          localStorage.setItem('apollo-refresh-token', resp.data.data.refresh_token);
          verifyToken(resp.data.data.access_token);
        }
      } catch (error) {
        ui.msg = error?.response?.data?.error?.description;
        ui.type = 'error';
        signingIn.value = false;
      }
    }

    async function verifyOtp() {
      try {
        verifying.value = true;

        const mobState = {
          type: 'phone_number_verify',
          phone_number: phoneState.formatted_number,
          code: otp.value
        };
        const emailState = {
          type: 'email_otp_verify',
          email: state.email,
          code: otp.value
        };

        const data = isEmailOtp.value ? emailState : mobState;

        const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/verify-otp`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.status === 200) {
          ui.type = 'success';
          otpMsg.value = resp.data.message;
          localStorage.setItem('apollo-refresh-token', resp.data.data.refresh_token);
          verifyToken(resp.data.data.access_token);
        }
      } catch (error) {
        otpMsg.value = 'Could not verify OTP';
        ui.type = 'error';
        verifying.value = false;
      }
    }

    async function forcedPass() {
      isChanging.value = true;
      try {
        const data = {
          user_id: userId.value,
          password: state.password,
          confirm_password: state.password,
          email: state.email
        };
        console.log(data);
        const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/force-password-change`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.status === 200) {
          collection.value!('User').findOneAndUpdate(
            {
              _id: userObjId.value
            },
            { $set: { forceChangedPwd: false } }
          );
          isChanging.value = false;
          state.forcedPassDialog = false;
        }
      } catch (error) {
        console.log(error);
      }
    }

    const checking = ref(false);
    function checkUserStatus() {
      checking.value = true;
      signingIn.value = true;
      collection.value!('User')
        .findOne({
          email: state.email
        })
        .then(user => {
          if (user?.forceChangedPwd) {
            console.log(user.userId);
            userId.value = user.userId;
            console.log(userId.value);
            userObjId.value = user._id;
            forcedPass().then(() => {
              signIn();
            });
          } else {
            signIn();
          }
        });
      checking.value = false;
    }

    async function resetPassEmail() {
      isSending.value = true;
      try {
        const data = {
          email: context.root.$route.hash.substring(2)
        };
        const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/change-password`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        isSending.value = false;
      } catch (error) {
        console.log(error);
      }
    }

    const verifyUser = async () => {
      try {
        await collection.value!('User')
          .findOne({ email: context.root.$route.hash.substring(2) })
          .then(async res => {
            if (res && res.invitedUser) {
              verificationMsg.value =
                'We have sent you instructions in your registered email address about setting up a password for the PilotCity account';
              await resetPassEmail();
            }
          });
      } catch {
        verificationMsg.value = 'We could not verify your email at this time';
      }
    };

    if (context.root.$route.hash) {
      verifyUser();
    }

    return {
      ...toRefs(state),
      ...toRefs(ui),
      login,
      verifyUser,
      userObjId,
      checkUserStatus,
      resetPassEmail,
      forcedPass,
      isEmailOtp,
      otpMsg,
      isSending,
      isEmail,
      isPhone,
      verifying,
      ref,
      isOtpSent,
      otp,
      sendResetPasswordEmail,
      phoneState,
      signIn,
      token,
      verifyToken,
      collection,
      signingIn,
      verifyOtp,
      checking,
      isChanging,
      isValidOtp,
      verificationMsg,
      getValidation,
      isPhoneValid
    };
  }
};
